import Sortable from "sortablejs";


function hideStatusElements() {
    document.querySelector("span.status-error").style.display = "none";
    document.querySelector("span.status-saved").style.display = "none";
    document.querySelector("span.status-saving").style.display = "none";
}


function onOrderSaveSuccess(xhr) {
    hideStatusElements();
    document.querySelector("span.status-saved").style.display = "inline-block";
    document.querySelector("span.status-saved").style.opacity = 1;

    setTimeout(function () {
        $("span.status-saved").fadeOut(2000);
    }, 1000);
}


function onOrderSaveFailure(xhr) {
    hideStatusElements();
    document.querySelector("span.status-error").style.display = "inline-block";
    document.querySelector("span.status-error span.error-text").textContent = "Error occurred during saving: " + xhr.responseText;
}


function handleSortStart(evt) {
    hideStatusElements();
}


function handleSort(evt) {
    document.querySelector("span.status-error").style.display = "none";
    document.querySelector("span.status-saved").style.display = "none";
    document.querySelector("span.status-saving").style.display = "inline-block";

    var order = [];
    const elements = document.querySelectorAll("tbody.sortable tr");

    for (var index = 0; index < elements.length; ++index) {
        const el = elements[index];

        if (el.className.includes('draggable--original') ||
            el.className.includes('draggable-mirror')) {
            continue;
        }

        order.push(el.getAttribute("data-val"));
    }

    document.querySelector("#song-sorter input[name=song_order]").value = order.join(";");

    var theform = document.querySelector("#song-sorter");

    $.ajax(theform.attributes.action.value, {
        method: "POST",
        data: $("#song-sorter").serialize()
    })
        .done(onOrderSaveSuccess)
        .fail(onOrderSaveFailure);
}

const onSongDeleted = function (element) {
    return function onSongDeleted(data) {
        element.parentElement.parentElement.remove();

        if (document.querySelectorAll("#songs-in-service tbody tr").length === 0) {
            document.querySelector("#no-song-warning").style.display = "block";
        }
    };
};

function handle_song_delete(evt) {
    evt.preventDefault();

    var csrftoken = jQuery("[name=csrfmiddlewaretoken]").val();

    var element = $(this)[0];

    $.ajax(element.href, {
        method: "POST",
        headers: {
            "X-CSRFToken": csrftoken,
        }
    })
        .done(onSongDeleted(element));
}


$(document).ready(function () {
    $("a.service-song-delete").click(handle_song_delete);

    var elements = $("#service-create .input-frontdatewidget input");

    if (elements.length !== 0) {
        elements.flatpickr({
            altInput: true,
            altFormat: "l J F, Y",
            dateFormat: "Y-m-d",
        });
    }

    var sortable_selector = document.querySelector('tbody.sortable');

    if (sortable_selector) {
        const sortable = Sortable.create(sortable_selector, {
            draggable: 'tr',
            handle: '.handle',
            onStart: handleSortStart,
            onEnd: handleSort
        });
    }
});
